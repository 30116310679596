<template>
<div class="backghui">
  <div class="big">
    <div class="form_title">
        <h1>CXSKINS 通知:</h1>
        <i class="iconfont icon-guanbi" @click="hanout()"></i>
    </div>
    <div class="tips_data" v-html="$options.filters.msg(istipsBoxdata)">
    </div>
    <div class="form_data">
        <div style="text-align: center;">
            <el-button type="info" @click="hanout()">确定</el-button>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    props:['istipsBox','istipsBoxdata'],
    data(){
        return {
           
        }
    },
    methods:{
        hanout(){
            this.$emit('update:istipsBox', false);
        }
    },
    //html过滤器 
   filters: {
    msg:function (msg) {
        return  msg
            .replace(/&amp;/g,'&')
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'");
        },
    }
}
</script>

<style lang="less" scoped>
.backghui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.big{
    width: 35%;
    background-color: #1E1D2A;
    margin:110px auto;
}
.tips_data{
    // text-align: center;
    padding:10px 20px;
    height: 50vh;
    overflow-y: scroll;
}
.form_data{
    padding: 30px 20px;
}
.form_title{
    display: flex;
    justify-content: space-between;
    padding:10px 20px;
    background-color: #282736;
    align-items: center;
    color: #D0D2E0;
    h1{
        font-size: 20px;
    }
    i{
        font-size: 25px;
        cursor:pointer;
    }
}
.el-button--info{
    background-color:#555370;
    border-color:#555370;
}
.el-button--info:hover{
    background-color:#65637c;
    border-color:#65637c;
}
@media screen and (max-width: 800px) {
    .big{
        margin: 20vw auto;
        width: 90%;
    }
    .form_data{
        padding: 10px;
    }
    .tips_data{
        height: 400px;
        overflow-y: scroll;
    }
}
</style>