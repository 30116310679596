<template>
  <div class="wrappers">
    <div v-on:mouseover="stop()" v-on:mouseout="move()" class="swiper">
      <transition-group tag="ul" name="image">
        <li v-for="(items, index) in list" v-show="index === num" :key="index">
          <!-- <img :src="items.logo" :alt="items.title" @click="$router.push({path:items.href,query:{id:items.id}})" /> -->
          <img :src="items.logo" :alt="items.title" @click="hanledNine(items.id,items.href)" />
        </li>
      </transition-group>
      <div class="circle" v-if="list.length != 1">
        <div
          v-for="(itemss,index) in list"
          class="nav"
          @click="change(index)"
          :class="{ active: index == num }"
          :key="index"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data: ["1"],
      time: "",
      num: 0,
      // list: [
      //   require("../assets/img/15.jpg"),
      //   require("../assets/img/15.(2).jpg"),
      //   require("../assets/img/bigimg.png")
      // ],
      list:[],
    };
  },
  mounted(){
    this.play();

  },
  created() {
    this.getCarousel()
  },
  methods: {
    //跳转九宫格抽奖
    hanledNine(id,href){
      let host = 'https://' + window.location.host + href
      window.open(host)
    },
    //请求轮播图
    getCarousel(){
      this.$axios({
        url:"/get/carousel",
        method:'post',
      }).then( res => {
        this.list = res.data.data.data;
      })
    },
    play() {
      this.time = setInterval(() => {
        this.num++;
        if (this.num == this.list.length) {
          this.num = 0;
        }
      }, 6000);
    },
    change(i) {
      this.num = i;
    },
    stop() {
      clearInterval(this.time);
    },
    move() {
      this.play();
    },
  },
};
</script>

<style lang="less" scoped>
.wrappers {
  height: 100%;
  width: 560px;
  display: flex;
}
.swiper {
    width: 100%;
    height: 220px;
    position: relative;
    img{
      cursor: pointer;
    }
}
img {
  width: 100%;
  height: 220px;
}
li {
  width: 100%;
  height: 100%;
  /* border: 5px solid white; */
  color: white;
  position: absolute;
}
ul {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius:10px;

}
.circle {
  position: absolute;
  right: 10px;
  bottom: 20px;
//   transform: translate(-50%, 0);
  display: flex;
  div{
    cursor: pointer;
  }
}
.nav {
  background-color: #ff9838;
  border-radius: 5px;
//   color: #ff9838;
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
  width: 10px;
  height: 10px;
  opacity: 0.2;
}
.active {
  opacity: 1;
}

.image-enter {
  transform: translateX(100%);

}
.image-enter-active {
  transition: all .5s linear;
}
.image-enter-to {
  transform: translateX(0);
}

.image-leave {
  transform: translateX(0);
}
.image-leave-active {
  transition: all .5s linear;
}
.image-leave-to {
  transform: translateX(-100%);
}
@media screen and (max-width: 800px) {
    .swiper{
      width: 100%;
    }
}
</style>
